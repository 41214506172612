import dynamic from 'next/dynamic';

import { ComponentType, MouseEvent, useMemo } from 'react';

import classNames from 'classnames';
import { useIsClient } from 'usehooks-ts';

import {
  DeserializedStorePageBannerBlock,
  DeserializedStorePageImageBannerBlock,
  DeserializedStorePageStoryBannerBlock,
} from '@api';

import NextLink from '@shared/common/components/NextLink';
import { useAuthModal } from '@shared/common/providers/GlobalModalsProvider';
import { sendTagManagerBannerClick } from '@shared/common/providers/GoogleTagManagerProvider';

import { useAuth } from '@store:web/components/providers/AuthProvider';
import { useUserAdSettings } from '@store:web/utils/hooks/useUserAdSettings';

import StoreBannerImageBlock from './StoreBannerImageBlock';
import StoreBannerStoryBlock from './StoreBannerStoryBlock';
import { StoreBlockProps } from './StoreBlock';

const StoreAdBannerBlock = dynamic(
  () => import('@store:web/components/common/StoreAdBlock/StoreAdBlock'),
  {
    ssr: false,
    loading: () => <div className="w-full aspect-[550/285] skeleton-block" />,
  },
);

const isBannerImageBlock = (
  block: DeserializedStorePageBannerBlock,
): block is DeserializedStorePageImageBannerBlock => {
  return !block.attributes.blockOptions?.type || block.attributes.blockOptions.type === 'IMAGE';
};

const isAdPlace = (block: DeserializedStorePageBannerBlock) => {
  return (
    block.attributes.blockOptions?.type &&
    block.attributes.blockOptions.type === 'IMAGE' &&
    block.attributes.blockOptions?.showAd &&
    block.attributes.blockOptions?.dataKey
  );
};

const isBannerStoryBlock = (
  block: DeserializedStorePageBannerBlock,
): block is DeserializedStorePageStoryBannerBlock => {
  return block.attributes.blockOptions?.type === 'STORY';
};

const getBannerBlock = (
  block: DeserializedStorePageBannerBlock,
): ComponentType<StoreBlockProps<DeserializedStorePageBannerBlock>> | null => {
  if (isBannerImageBlock(block)) {
    return StoreBannerImageBlock as ComponentType<
      StoreBlockProps<DeserializedStorePageBannerBlock>
    >;
  } else if (isBannerStoryBlock(block)) {
    return StoreBannerStoryBlock as ComponentType<
      StoreBlockProps<DeserializedStorePageBannerBlock>
    >;
  }

  return null;
};

const StoreBannerBlock = ({
  block,
  className,
  imagePriority,
}: StoreBlockProps<DeserializedStorePageBannerBlock>) => {
  const { isAuth, user } = useAuth();
  const { enabled: isAdEnable } = useUserAdSettings();
  const isClient = useIsClient();
  const authModal = useAuthModal();
  const SpecificBannerBlock = getBannerBlock(block);
  const {
    attributes: { link },
  } = block;
  const origin = typeof window === 'undefined' ? process.env.NEXT_PUBLIC_HOST : window.origin;
  const url = new URL(link, origin);
  const urlPathname = url.pathname;
  const isSameOrigin = url.origin === origin;
  const href = isSameOrigin ? `${url.pathname}${url.search}` : link;
  const isProtectedRoute = useMemo(() => {
    return ['/profile', '/refer-a-friend', '/magic-boost', '/discordConnect', '/developer'].some(
      (pathname) => urlPathname.startsWith(pathname),
    );
  }, [urlPathname]);

  const handleBannerLinkClick = (event: MouseEvent<HTMLAnchorElement>) => {
    if (!isAuth && isProtectedRoute) {
      event.preventDefault();

      authModal.open();
    }

    sendTagManagerBannerClick({
      bannerId: href,
    });
  };

  if (!isClient) {
    return <div className="skeleton-block w-full aspect-[550/285]" />;
  }

  if (!SpecificBannerBlock) {
    return null;
  }

  if (isAdPlace(block) && isAdEnable) {
    const dataKey =
      block.attributes.blockOptions?.type === 'IMAGE' ? block.attributes.blockOptions.dataKey : ''; // Impossible case because check all in isAdPlace;

    return (
      <StoreAdBannerBlock
        dataKey={dataKey}
        className="aspect-[550/285] store-block-card overflow-hidden"
        userId={user?.id}
      />
    );
  }

  return (
    <NextLink
      // NOTE: if route is protected and user is unauth we hide a href to disable prefetch on hover
      // When unauth user hovers over link, Next will generate a page, but when a page is protected Next
      // will generate response that will redirect user on home page.
      // Because of this behavior, when user try to authorize and then click on a link, instead of opening it,
      // it will redirection user on home page
      href={isProtectedRoute && !isAuth ? '' : href}
      className={classNames(
        'block store-block-card border border-primary-200 overflow-hidden aspect-[550/285]',
        {
          // disable link during page hydration (because handler, that prevents unauth user from opening link,
          // is not set yet during this time, making it possible to open a link)
          'pointer-events-none': isProtectedRoute && !isClient,
        },
        className,
      )}
      // added isProtectedRoute as possible fix for opening store related link in store
      {...(isSameOrigin || isProtectedRoute
        ? {}
        : {
            target: '_blank',
            rel: 'noreferrer',
          })}
      onClick={handleBannerLinkClick}
      data-qa="banner"
    >
      <SpecificBannerBlock block={block} imagePriority={imagePriority} className={className} />
    </NextLink>
  );
};

export default StoreBannerBlock;
